import ReviewsService from '@/services/modules/reviews.service';

export default {
   namespaced: true,

   state: {
      reviews: [],
      reviewsCount: 0,
      lastReviewId: 0,
      errorMessage: null
   },

   mutations: {
      SET_REVIEWS(state, data) {
         state.reviews = data;
      },

      SET_REVIEWS_COUNT(state, data) {
         state.reviewsCount = data;
      },

      SET_LAST_ID(state, data) {
         state.lastReviewId = data;
      },

      SET_ERROR_MESSAGE(state, error) {
         state.errorMessage = error;
      }
   },

   actions: {
      async fetchReviews({ commit }, { page, limit }) {
         try {
            const { reviews, reviewsCount } = await ReviewsService.getReviews(page, limit);

            commit('SET_ERROR_MESSAGE', null);
            commit('SET_REVIEWS', reviews);
            commit('SET_REVIEWS_COUNT', reviewsCount);
         } catch (error) {
            commit('SET_ERROR_MESSAGE', error);
            commit('SET_REVIEWS', []);
            commit('SET_REVIEWS_COUNT', 0);
         }
      },

      async fetchLastId({ commit }) {
         try {
            const id = await ReviewsService.getLastReviewId();
            commit('SET_LAST_ID', id);
         } catch (error) {
            commit('SET_ERROR_MESSAGE', error);
         }
      },

      async setReview(state, data) {
         try {
            const response = await ReviewsService.setReview(data);
            return Boolean(Object.keys(response).length);
         } catch (error) {
            throw new Error(error);
         }
      }
   }
};
