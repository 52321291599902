'use strict';

export const openModalWindow = (upButton) => {
   const body = document.body,
      scrollWidth = window.innerWidth - body.offsetWidth;

   body.style.overflow = 'hidden';

   if (window.screen.width > 1024) {
      body.style.paddingRight = `${scrollWidth}px`;
   }

   if (window.screen.width > 1024 && upButton) {
      const upButtonStyleRight = parseInt(window.getComputedStyle(upButton).right);

      upButton.style.right = `${upButtonStyleRight + scrollWidth}px`;
   }
};
