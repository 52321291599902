import { createRouter, createWebHistory } from 'vue-router';
import NotFound from '@/components/pages/404';

const routes = [
   {
      path: '/',
      name: 'Home',
      component: () => import('@/components/pages/Homepage/Homepage')
   },
   {
      path: '/catalog',
      name: 'Catalog',
      component: () => import('@/components/pages/Catalog/Catalog')
   },
   {
      path: '/product/:id',
      name: 'Product',
      component: () => import('@/components/pages/ProductPage/ProductPage'),
      props: true
   },
   {
      path: '/order',
      name: 'Order',
      component: () => import('@/components/pages/OrderPage/OrderPage')
   },
   {
      path: '/blog',
      name: 'Blog',
      component: () => import('@/components/pages/Blog/Blog')
   },
   {
      path: '/post/:id',
      name: 'Post',
      component: () => import('@/components/pages/Blog/PostPage'),
      props: true
   },
   {
      path: '/reviews',
      name: 'Reviews',
      component: () => import('@/components/pages/Reviews/Reviews')
   },
   {
      path: '/404',
      name: 'NotFound',
      component: NotFound
   },
   {
      path: '/:catchAll(.*)',
      component: NotFound,
      redirect: NotFound
   }
];

const router = createRouter({
   history: createWebHistory(process.env.BASE_URL),
   routes
});

export default router;
