<template>
   <nav class="nav-menu body__nav-menu">
      <div class="nav-menu__container container">
         <button
            @click="$router.push({ name: 'Catalog', query: { page: 1 } })"
            class="nav-menu__btn btn"
         ></button>

         <ul class="nav-menu__list">
            <li v-for="{ name, category } in menu" :key="name" class="nav-menu__item">
               <router-link
                  :to="{ name: 'Catalog', query: { filter: category, page: 1 } }"
                  class="nav-menu__link"
               >
                  {{ name }}
               </router-link>
            </li>
         </ul>

         <product-search class="nav-menu__search" />
      </div>
   </nav>
</template>

<script>
   import ProductSearch from './modules/ProductSearch';

   export default {
      name: 'AppNavigation',

      components: {
         ProductSearch
      },

      data() {
         return {
            menu: [
               { name: 'Футболки', category: 'Футболки' },
               { name: 'Шорты', category: 'Шорты' },
               { name: 'Шапки', category: 'Шапки' }
            ]
         };
      }
   };
</script>
