<template>
   <svg height="512pt" viewBox="0 0 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg">
      <path
         d="m489.410156 21.847656c-14.410156-14.089844-33.378906-21.847656-53.410156-21.847656h-361c-41.355469 0-75 33.644531-75 75v362c0 41.355469 33.644531 75 75 75h362c41.355469 0 75-33.644531 75-75v-362c0-20.039062-8.023438-38.914062-22.589844-53.152344zm-218.410156 249.152344h-30v-30h30c8.285156 0 15-6.714844 15-15 0-41.140625-.003906-59.957031-.003906-68.613281-.003906-15.933594.019531-33.289063 15.582031-46.605469 11.863281-10.148438 26.984375-16.277344 47.59375-19.292969 21.742187-3.175781 43.808594-2.183593 67.113281 3.046875-1.769531 11.910156-3.867187 26.019532-5.449218 36.652344-8.84375-1.792969-24.019532-3.242188-36.441407-1.136719-27.578125 4.664063-43.394531 21.777344-43.394531 46.949219v49c0 8.285156 6.714844 15 15 15h55.789062l-7.5 30h-48.289062c-8.285156 0-15 6.714844-15 15v196h-45v-196c0-8.285156-6.714844-15-15-15zm211 166c0 24.8125-20.1875 45-45 45h-76v-181h45c6.882812 0 12.882812-4.683594 14.550781-11.363281l15-60c1.121094-4.480469.113281-9.226563-2.726562-12.867188-2.84375-3.640625-7.207031-5.769531-11.824219-5.769531h-60v-34c0-6.898438 1.421875-14.5 18.40625-17.375 13.289062-2.25 25.164062.71875 35.640625 3.34375 5.035156 1.257812 10.453125 3.175781 16.628906-1.003906 3.476563-2.355469 5.808594-6.058594 6.429688-10.210938 0 0 6.738281-45.269531 9.84375-66.199218 1.105469-7.453126-3.484375-14.570313-10.734375-16.632813-29.296875-8.332031-65.132813-11.070313-92.183594-7.117187-26.460938 3.871093-46.601562 12.1875-62.957031 26.183593-22.753907 19.46875-25.578125 44.449219-25.871094 61.484375-.007813.347656-.003906 30.648438-.003906 61.527344h-30.199219c-8.285156 0-15 6.714844-15 15v60c0 8.285156 6.714844 15 15 15h30v181h-181c-24.8125 0-45-20.1875-45-45v-362c0-24.8125 20.1875-45 45-45h361c24.933594 0 46 20.609375 46 45zm0 0"
      />
   </svg>
</template>

<script>
   export default {
      name: 'FacebookIcon'
   };
</script>
