<template>
   <header class="header">
      <div class="header__container container">
         <address class="header__header-contacts header-contacts">
            <a class="header-contacts__link" href="tel:89871880808">8 (987) 188 08 08</a>
            <a class="header-contacts__link" href="tel:89683550505">8 (968) 355 05 05</a>
         </address>

         <button @click="openModalWindow('ModalCallBack')" class="header__callback btn btn--bright">
            Перезвоните мне
         </button>

         <router-link :to="{ name: 'Home' }" class="logo">
            <img src="@/assets/images/logo.webp" alt="Логотип 'Hope Shop'" />
         </router-link>
         <h1 class="title">Интернет-магазин Hope Shop</h1>

         <div class="header__nav-tools nav-tools">
            <button
               v-for="{ modal, className, icon, alt, text, content } in navTools"
               :key="className"
               :class="[className, 'btn']"
               :data-content="content ?? productsCount"
               @click="openModalWindow(modal)"
            >
               <svg-icon-template :class="[`${className}__img`]" :icon-name="icon" :alt="alt" />

               <span :class="[`${className}__text`]">{{ text }}</span>
            </button>
         </div>
      </div>
   </header>
</template>

<script>
   import SvgIconTemplate from '@/components/templates/SvgIconTemplate';
   import { mapGetters } from 'vuex';

   export default {
      name: 'AppHeader',

      components: {
         SvgIconTemplate
      },

      emits: {
         'app-header': (value) => typeof value === 'object'
      },

      inject: ['openModal'],

      data() {
         return {
            navTools: [
               {
                  modal: 'ModalAccount',
                  className: 'log-in',
                  icon: 'log-in',
                  alt: 'Вход в личный кабинет',
                  text: 'Вход',
                  content: ''
               },
               {
                  modal: 'ModalFavorites',
                  className: 'favourites',
                  icon: 'favorites',
                  alt: 'Избранное',
                  text: 'Избранное',
                  content: ''
               },
               {
                  modal: 'ModalCart',
                  className: 'cart',
                  icon: 'cart',
                  alt: 'Корзина товаров',
                  text: 'Корзина',
                  content: null
               }
            ]
         };
      },

      mounted() {
         this.$emit('app-header', this.$el);
      },

      methods: {
         openModalWindow(name) {
            this.openModal({ name, isActive: true });
         }
      },

      computed: {
         ...mapGetters('cart', ['productsCount'])
      }
   };
</script>
