<template>
   <focus-trap v-model:active="isActiveModal">
      <article
         :class="{ 'modal--active': isActiveModal, 'modal--overflow': isActiveModal }"
         @click.self="closeModal"
         class="modal"
         tabindex="-1"
      >
         <div
            :class="{ 'modal--active': isActiveModal }"
            :data-modal="settings.name"
            class="modal__wrapper"
         >
            <header v-show="!isNotification" class="modal-header">
               <h2 class="modal-header__title">{{ title }}</h2>

               <button @click="closeModal" class="modal-header__btn btn"></button>
            </header>

            <component :is="modal" :modalSettings="settings" v-model:title="title" />
         </div>
      </article>
   </focus-trap>
</template>

<script>
   import { FocusTrap } from 'focus-trap-vue';
   import { openModalWindow } from '@/components/modal/utils/openModalWindow';
   import { closeModalWindow } from '@/components/modal/utils/closeModalWindow';
   import { preload } from '@/components/modal/utils/preload';

   export default {
      name: 'AppModal',

      components: {
         FocusTrap
      },

      emits: {
         'modal-methods': (value) => typeof value === 'object'
      },

      inject: ['upButton'],

      data() {
         return {
            settings: {},
            isActiveModal: false,
            title: ''
         };
      },

      beforeMount() {
         this.$emit('modal-methods', { openModal: this.openModal, closeModal: this.closeModal });
      },

      mounted() {
         document.addEventListener('keyup', this.hideModal);
      },

      beforeUnmount() {
         document.removeEventListener('keyup', this.hideModal);
      },

      methods: {
         openModal(settings) {
            this.settings = settings;

            if (settings.name && !this.isActiveModal) {
               this.isActiveModal = settings.isActive;
               openModalWindow(this.upButton);
            }
         },

         hideModal(e) {
            //hideModal === closeModal
            if (e.key === 'Escape') {
               this.closeModal();
            }
         },

         closeModal() {
            setTimeout(() => {
               this.settings = {};
            }, 500);

            this.isActiveModal = false;
            this.title = '';

            closeModalWindow(this.upButton);
         }
      },

      computed: {
         modal() {
            return this.settings.name ? preload(this.settings.name) : null;
         },

         isNotification() {
            return this.settings.name === 'ModalNotification';
         }
      }
   };
</script>
