import { request } from '../generic.service';

class ReviewsService {
   async getReviews(page, limit) {
      const params = new URLSearchParams({ page, limit }).toString();
      const reviews = await request('reviews', `?${params}`, 'GET');

      return await reviews.json();
   }

   async getLastReviewId() {
      const id = await request('reviews', 'last-id', 'GET');
      return await id.json();
   }

   async setReview(review) {
      const result = await request('reviews', '', 'POST', review);
      return result.json();
   }
}

export default new ReviewsService();
