import { request } from '../generic.service';

class ProductService {
   async getProduct(id) {
      const response = await request('products', id, 'GET');
      const result = await response.json();

      return result[0];
   }

   async getSimilarProducts(section, value) {
      const params = new URLSearchParams({ section, value }).toString();
      const products = await request('products', `category?${params}`, 'GET');

      return await products.json();
   }
}

export default new ProductService();
