import FiltersService from '@/services/modules/filters.service';

export default {
   namespaced: true,

   state: {
      filters: [],
      errorMessage: null
   },

   mutations: {
      SET_FILTERS(state, data) {
         state.filters = data;
      },

      SET_ERROR_MESSAGE(state, error) {
         state.errorMessage = error;
      }
   },

   actions: {
      async fetchFilters({ commit }) {
         try {
            const filters = await FiltersService.getFilters();

            commit('SET_ERROR_MESSAGE', null);
            commit('SET_FILTERS', filters);
         } catch (error) {
            commit('SET_ERROR_MESSAGE', error);
         }
      }
   }
};
