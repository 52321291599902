<template>
   <img :src="pathIcon" />
</template>

<script>
   export default {
      name: 'SvgIconTemplate',

      props: {
         iconName: { type: String, required: true, default: '' }
      },

      computed: {
         pathIcon() {
            return `${require('@/assets/images/icons/icons.svg')}#${this.iconName}`;
         }
      }
   };
</script>
