import { createApp } from 'vue';
import App from './App.vue';

import './assets/styles/main.sass';
import router from './router';
import store from './store';

const app = createApp(App);

app.config.unwrapInjectedRef = true;
app.config.globalProperties.currency = '₽';

app.use(store).use(router).mount('#app');
