import ProductsService from '@/services/modules/products.service';

export default {
   namespaced: true,

   state: {
      products: [], //component "Catalog"
      sellingProducts: [], //component "Homepage/SaleProducts"
      newProducts: [], //component "Homepage/NewProducts"
      foundProducts: [], //component "ProductSearch"
      productsCount: 0,
      notificationType: 'Loading',
      errorMessage: null
   },

   mutations: {
      SET_PRODUCTS(state, { stateName, products: data }) {
         state[stateName] = data;
      },

      SET_PRODUCTS_COUNT(state, data) {
         state.productsCount = data;
      },

      SET_NOTIFICATION_TYPE(state, data) {
         state.notificationType = data;
      },

      SET_ERROR_MESSAGE(state, error) {
         state.errorMessage = error;
      },

      REMOVE_PRODUCTS(state, stateName) {
         state[stateName] = [];
      }
   },

   actions: {
      async fetchProductsFromCategory({ commit }, { stateName, section, value }) {
         try {
            const products = await ProductsService.getProductsFromCategory(section, value);
            commit('SET_PRODUCTS', { stateName, products });
         } catch (error) {
            commit('SET_ERROR_MESSAGE', error);
         }
      },

      async fetchProducts({ commit }, { stateName, query }) {
         try {
            const { products, productsCount } = await ProductsService.getProducts(query);

            commit('SET_ERROR_MESSAGE', null);
            commit('SET_NOTIFICATION_TYPE', 'Loading');
            commit('SET_PRODUCTS', { stateName, products });
            commit('SET_PRODUCTS_COUNT', productsCount);
         } catch (error) {
            commit('SET_ERROR_MESSAGE', error);
            commit('SET_NOTIFICATION_TYPE', error.status === 404 ? 'NotFound' : 'Loading');
            commit('SET_PRODUCTS', { stateName, products: [] });
            commit('SET_PRODUCTS_COUNT', 0);
         }
      },

      removeProducts({ commit }, stateName) {
         try {
            commit('REMOVE_PRODUCTS', stateName);
         } catch (error) {
            commit('SET_ERROR_MESSAGE', error);
         }
      }
   }
};
