import PostsService from '@/services/modules/posts.service';

export default {
   namespaced: true,

   state: {
      post: {},
      errorMessage: null
   },

   mutations: {
      SET_POST(state, data) {
         state.post = data;
      },

      SET_ERROR_MESSAGE(state, error) {
         state.errorMessage = error;
      }
   },

   actions: {
      async fetchPost({ commit }, id) {
         try {
            const post = await PostsService.getPost(id);

            commit('SET_ERROR_MESSAGE', null);
            commit('SET_POST', post);
         } catch (error) {
            commit('SET_ERROR_MESSAGE', error);
         }
      }
   }
};
