<template>
   <button
      :class="{ 'scroll-top--active': isDisplayed }"
      @click="backToTopOfPage"
      class="scroll-top btn"
      title="Вернуться к началу страницы"
   >
      <svg
         class="scroll-top__img"
         id="Layer_1"
         xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink"
         x="0px"
         y="0px"
         viewBox="0 0 491.996 491.996"
         xml:space="preserve"
      >
         <path
            d="M484.132,124.986l-16.116-16.228c-5.072-5.068-11.82-7.86-19.032-7.86c-7.208,0-13.964,2.792-19.036,7.86l-183.84,183.848L62.056,108.554c-5.064-5.068-11.82-7.856-19.028-7.856s-13.968,2.788-19.036,7.856l-16.12,16.128c-10.496,10.488-10.496,27.572,0,38.06l219.136,219.924c5.064,5.064,11.812,8.632,19.084,8.632h0.084c7.212,0,13.96-3.572,19.024-8.632l218.932-219.328c5.072-5.064,7.856-12.016,7.864-19.224C491.996,136.902,489.204,130.046,484.132,124.986z"
         />
      </svg>
   </button>
</template>

<script>
   import smoothScroll from 'smoothscroll-polyfill';

   export default {
      name: 'AppScrollTop',

      emits: {
         'up-button': (value) => typeof value === 'object'
      },

      inject: ['header'],

      data() {
         return {
            isDisplayed: false
         };
      },

      mounted() {
         this.$emit('up-button', this.$el);

         document.addEventListener('scroll', this.getHeaderPosition);
      },

      beforeUnmount() {
         document.removeEventListener('scroll', this.getHeaderPosition);
      },

      methods: {
         getHeaderPosition() {
            const headerPosition = this.header.getBoundingClientRect().top;

            this.isDisplayed = headerPosition <= -300;
         },

         backToTopOfPage() {
            smoothScroll.polyfill();
            this.header.scrollIntoView({ behavior: 'smooth' });
         }
      }
   };
</script>
