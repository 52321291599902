import { request } from '../generic.service';

class PostsService {
   async getPost(id) {
      const response = await request('posts', id, 'GET');
      const result = await response.json();

      return result[0];
   }

   async getPosts(page, limit) {
      const params = new URLSearchParams({ page, limit }).toString();
      const response = await request('posts', `?${params}`, 'GET');

      return await response.json();
   }
}

export default new PostsService();
