const API_URL = 'http://pethope.ru/api';

export const request = async (section, url, method, data = null) => {
   const response = await fetch(`${API_URL}/${section}/${url}`, {
      headers: {
         'Content-Type': 'application/json; charset=utf-8'
      },
      method,
      body: data ? JSON.stringify(data) : null
   });

   if (!response.ok) {
      throw { error: response.statusText, status: response.status, url: response.url };
   }

   return response;
};
