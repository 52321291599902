'use strict';

export default {
   namespaced: true,

   state: {
      products: []
   },

   getters: {
      productsCount(state) {
         return state.products.length;
      }
   },

   mutations: {
      SET_PRODUCTS(state, value) {
         state.products = value;
      },

      ADD_PRODUCT(state, product) {
         const isProductFound = state.products.some((item) => item.id === product.id);

         if (!isProductFound) {
            state.products.push(product);
         }
      },

      REMOVE_PRODUCT(state, product) {
         const productIdx = state.products.indexOf(product);
         state.products.splice(productIdx, 1);
      }
   },

   actions: {
      loadDataFromLocalStorage({ commit }) {
         const data = localStorage.getItem('favorites');

         if (data) {
            commit('SET_PRODUCTS', JSON.parse(data));
         }
      },

      saveProductState({ commit, state }, { product, isRemove }) {
         commit(isRemove ? 'REMOVE_PRODUCT' : 'ADD_PRODUCT', product);
         localStorage.setItem('favorites', JSON.stringify(state.products));
      }
   }
};
