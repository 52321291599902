'use strict';

export default {
   namespaced: true,

   state: {
      products: []
   },

   getters: {
      productsCount(state) {
         return state.products.length;
      }
   },

   mutations: {
      SET_PRODUCTS(state, value) {
         state.products = value;
      },

      ADD_PRODUCT(state, product) {
         const isProductFound = state.products.some((item) => {
            if (item.id === product.id) {
               item.count += 1;
               return true;
            }
         });

         if (!isProductFound) {
            state.products.push(product);
         }
      },

      REMOVE_PRODUCT(state, product) {
         const idxProduct = state.products.indexOf(product);

         state.products[idxProduct].count = 1;
         state.products.splice(idxProduct, 1);
      },

      INCREASE_PRODUCT_COUNT(state, product) {
         state.products.forEach((item) => {
            if (item.id === product.id) {
               item.count += 1;
            }
         });
      },

      DECREASE_PRODUCT_COUNT(state, product) {
         state.products.forEach((item) => {
            if (item.id === product.id) {
               item.count -= 1;
            }
         });
      },

      ENTER_PRODUCT_COUNT(state, { product, value }) {
         state.products.forEach((item) => {
            if (item.id === product.id) {
               item.count = value;
            }
         });
      },

      EMPTY_TRASH(state) {
         state.products = [];
      }
   },

   actions: {
      loadDataFromLocalStorage({ commit }) {
         const data = localStorage.getItem('cart');

         if (data) {
            commit('SET_PRODUCTS', JSON.parse(data));
         }
      },

      saveProductState({ commit, state }, { product, isRemove }) {
         commit(isRemove ? 'REMOVE_PRODUCT' : 'ADD_PRODUCT', product);
         localStorage.setItem('cart', JSON.stringify(state.products));
      },

      saveProductCountState({ commit, state }, { product, isIncrease }) {
         commit(isIncrease ? 'INCREASE_PRODUCT_COUNT' : 'DECREASE_PRODUCT_COUNT', product);
         localStorage.setItem('cart', JSON.stringify(state.products));
      },

      updateProductCountState({ commit, state }, { product, value }) {
         commit('ENTER_PRODUCT_COUNT', { product, value });
         localStorage.setItem('cart', JSON.stringify(state.products));
      },

      emptyTrash({ commit, state }) {
         commit('EMPTY_TRASH');
         localStorage.setItem('cart', JSON.stringify(state.products));
      }
   }
};
