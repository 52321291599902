import ProductService from '@/services/modules/product.service';

export default {
   namespaced: true,

   state: {
      product: {},
      similarProducts: [],
      errorMessage: null
   },

   mutations: {
      SET_PRODUCT(state, data) {
         state.product = data;
      },

      SET_SIMILAR_PRODUCTS(state, data) {
         state.similarProducts = data;
      },

      SET_ERROR_MESSAGE(state, error) {
         state.errorMessage = error;
      }
   },

   actions: {
      async fetchProduct({ commit }, id) {
         try {
            const product = await ProductService.getProduct(id);

            commit('SET_ERROR_MESSAGE', null);
            commit('SET_PRODUCT', product);
         } catch (error) {
            commit('SET_ERROR_MESSAGE', error);
         }
      },

      async fetchSimilarProducts({ commit }, { section, value }) {
         try {
            const products = await ProductService.getSimilarProducts(section, value);

            products.forEach((product) => {
               product.image = require(`@/assets/images/products/${product.images.folder}/${product.preview}`);
               product.imageDescription = `Товар '${product.name}'`;
            });

            commit('SET_SIMILAR_PRODUCTS', products);
         } catch (error) {
            commit('SET_ERROR_MESSAGE', error);
         }
      }
   }
};
