import { defineAsyncComponent } from 'vue';
import ModalPreload from '@/components/modal/modules/ModalPreload';

export const preload = (componentName) =>
   defineAsyncComponent({
      loader: () => import(`../modules/${componentName}/${componentName}.vue`),
      loadingComponent: ModalPreload,
      errorComponent: ModalPreload,
      delay: 0,
      timeout: 3000
   });
