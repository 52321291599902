<template>
   <article class="search">
      <h2 class="search__title">Поиск товаров</h2>

      <div class="search__wrap">
         <input
            ref="search"
            v-model.trim="searchField"
            @keydown.enter="goToProductPage"
            @input="inputFieldWatcher"
            class="search__input input"
            type="search"
            placeholder="Что Вы хотите найти?"
         />

         <button
            v-if="searchField.length"
            @click="clearSearch"
            class="search__clear-btn btn"
         ></button>

         <button :disabled="isDisabled" @click="goToProductPage" class="search__btn btn">
            <svg-icon-template :icon-name="'search'" class="search__btn-img" alt="Поиск товаров" />
         </button>
      </div>

      <ul v-if="products.length" class="search__search-results search-results">
         <li v-for="product in products" :key="product.id" class="search-results__item">
            <button @click="selectFoundProduct(product)" class="search-results__btn btn">
               {{ product.name }}
            </button>
         </li>
      </ul>
   </article>
</template>

<script>
   import SvgIconTemplate from '@/components/templates/SvgIconTemplate';
   import { mapState, mapActions } from 'vuex';

   export default {
      name: 'ProductSearch',

      components: {
         SvgIconTemplate
      },

      data() {
         return {
            searchField: '',
            selectedProduct: {}
         };
      },

      methods: {
         ...mapActions('products', {
            fetchProducts: 'fetchProductsFromCategory',
            removeProducts: 'removeProducts'
         }),

         clearSearch() {
            this.searchField = '';
            this.selectedProduct = {};
         },

         selectFoundProduct(product) {
            this.searchField = product.name;
            this.selectedProduct = product;

            this.removeProducts('foundProducts');

            this.$refs.search.focus();
         },

         goToProductPage() {
            if (this.selectedProduct.id) {
               this.$router.push({ name: 'Product', params: { id: this.selectedProduct.id } });

               this.clearSearch();
               this.$refs.search.blur();
            }
         },

         getProducts(callback, time) {
            let timerId = null;

            return () => {
               timerId && clearTimeout(timerId); //if

               timerId = setTimeout(() => {
                  if (this.searchField) {
                     timerId = null;

                     callback({
                        stateName: 'foundProducts',
                        section: 'name',
                        value: this.searchField.toLowerCase()
                     });
                  }
               }, time);
            };
         }
      },

      computed: {
         ...mapState('products', ['foundProducts']),

         isDisabled() {
            return this.selectedProduct.name !== this.searchField;
         },

         products() {
            return this.foundProducts.filter(
               (product) => this.searchField !== product.name && this.searchField
            );
         },

         inputFieldWatcher() {
            return this.getProducts(this.fetchProducts, 100);
         }
      },

      watch: {
         searchField(value) {
            if (value === '') {
               this.removeProducts('foundProducts');
            }
         }
      }
   };
</script>
