<template>
   <main class="main">
      <article class="main__not-found not-found">
         <div class="not-found__container container">
            <img
               :src="require('@/assets/images/404.png')"
               class="not-found__img"
               alt="Страница 404"
            />

            <div class="not-found__wrap">
               <h2 class="not-found__title"><span>404</span> Page not found</h2>

               <p class="not-found__text">
                  Извините, но&nbsp;такой страницы нет на&nbsp;нашем сайте. <br />
                  Возможно, вы&nbsp;ввели неправильный адрес или страница была удалена
               </p>

               <ul class="not-found__list">
                  <li v-for="(item, idx) in routes" :key="idx" class="not-found__item">
                     <router-link :to="item.route" class="not-found__link">
                        {{ item.title }}
                     </router-link>
                  </li>
               </ul>
            </div>
         </div>
      </article>
   </main>
</template>

<script>
   export default {
      name: 'NotFound',

      data() {
         return {
            routes: [
               { title: 'Главная', route: { name: 'Home' } },
               { title: 'Каталог', route: { name: 'Catalog', query: { page: 1 } } },
               { title: 'Страница блога', route: { name: 'Blog', query: { page: 1 } } }
            ]
         };
      }
   };
</script>
