import PostsService from '@/services/modules/posts.service';

export default {
   namespaced: true,

   state: {
      posts: [],
      postsCount: 0,
      errorMessage: null
   },

   mutations: {
      SET_POSTS(state, data) {
         state.posts = data;
      },

      SET_POSTS_COUNT(state, data) {
         state.postsCount = data;
      },

      SET_ERROR_MESSAGE(state, error) {
         state.errorMessage = error;
      }
   },

   actions: {
      async fetchPosts({ commit }, { page, limit }) {
         try {
            const { posts, postsCount } = await PostsService.getPosts(page, limit);

            commit('SET_ERROR_MESSAGE', null);
            commit('SET_POSTS', posts);
            commit('SET_POSTS_COUNT', postsCount);
         } catch (error) {
            commit('SET_ERROR_MESSAGE', error);
            commit('SET_POSTS', []);
            commit('SET_POSTS_COUNT', 0);
         }
      }
   }
};
