<template>
   <section class="advantages body__advantages">
      <div class="advantages__container container">
         <h2 class="advantages__title">Преимущества</h2>

         <ul class="advantages__list">
            <li class="advantages__item advantages__item--img_experience">
               15&nbsp;лет в&nbsp;оффлайне и&nbsp;5&nbsp;лет в&nbsp;интернете
            </li>
            <li class="advantages__item advantages__item--img_refund">
               2&nbsp;недели на&nbsp;обмен и&nbsp;возврат товара
            </li>
            <li class="advantages__item advantages__item--img_check">
               Товар проверяем перед отправкой
            </li>
            <li class="advantages__item advantages__item--img_discount">
               Накопительные скидки, подарки и&nbsp;акции!
            </li>
            <li class="advantages__item advantages__item--img_delivery">
               Бесплатная доставка от&nbsp;5000&nbsp;руб.&nbsp;по&nbsp;России
            </li>
         </ul>
      </div>
   </section>
</template>

<script>
   export default {
      name: 'AppAdvantages'
   };
</script>
