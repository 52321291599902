import { request } from '../generic.service';

class FiltersService {
   async getFilters() {
      const response = await request('filters', '', 'GET');
      return await response.json();
   }
}

export default new FiltersService();
