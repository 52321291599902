<template>
   <footer class="footer">
      <div class="container">
         <h2 class="footer__title">Дополнительная навигация</h2>

         <div class="footer__wrap">
            <div class="nav-section nav-section--contacts">
               <h3 class="nav-section__title">Свяжитесь с нами</h3>

               <ul class="nav-section__list">
                  <li class="nav-section__item">
                     <a class="nav-section__link" href="tel:+79871880808">+7 987 188 08 08</a>
                  </li>
                  <li class="nav-section__item">
                     <button @click="openModalWindow" class="nav-section__btn btn">
                        Перезвоните мне
                     </button>
                  </li>
                  <li v-for="page in pages" :key="page.name" class="nav-section__item">
                     <router-link
                        :to="{ name: page.route, query: { page: page.startPage } }"
                        class="nav-section__link"
                     >
                        {{ page.name }}
                     </router-link>
                  </li>
               </ul>
            </div>

            <div class="nav-section nav-section--help">
               <h3 class="nav-section__title">Помощь</h3>

               <ul class="nav-section__list">
                  <li v-for="item in customerAssistance" :key="item.name" class="nav-section__item">
                     <a :href="item.link" class="nav-section__link">{{ item.name }}</a>
                  </li>
               </ul>
            </div>

            <div class="nav-section">
               <h3 class="nav-section__title">Мой кабинет</h3>

               <ul class="nav-section__list">
                  <li v-for="item in profile" :key="item.name" class="nav-section__item">
                     <a :href="item.link" class="nav-section__link">{{ item.name }}</a>
                  </li>
               </ul>
            </div>

            <div class="footer__delivery delivery">
               <h3 class="delivery__title">Быстрая доставка</h3>

               <a class="delivery__link" href="#">
                  <img
                     class="delivery__logo"
                     src="@/assets/images/mail.webp"
                     alt="Доставка 'Почта России'"
                  />
               </a>
            </div>

            <social-networks class="footer__social-networks">
               <template #title>Добавляйся к нам</template>
            </social-networks>
         </div>

         <small class="footer__copyright">
            &copy;&nbsp;www.web-name.com 2021-2022. Все&nbsp;права&nbsp;защищены.
         </small>

         <app-scroll-top @up-button="transferUpButton" class="footer__scroll-top" />
      </div>
   </footer>
</template>

<script>
   import SocialNetworks from '@/components/templates/SocialNetworks';
   import AppScrollTop from './AppScrollTop';

   export default {
      name: 'AppFooter',

      components: {
         SocialNetworks,
         AppScrollTop
      },

      emits: {
         'up-button': (value) => typeof value === 'object'
      },

      inject: ['openModal'],

      data() {
         return {
            pages: [
               { name: 'Блог', route: 'Blog', startPage: 1 },
               { name: 'Новости Блога', route: 'Blog', startPage: 1 },
               { name: 'Отзывы', route: 'Reviews', startPage: 1 }
            ],
            customerAssistance: [
               { name: 'Условия доставки', link: '#' },
               { name: 'Условия оплаты', link: '#' },
               { name: 'Условия возврата', link: '#' },
               { name: 'Гарантия', link: '#' },
               { name: 'Как применить промокод', link: '#' },
               { name: 'Вернуть товар. Инструкция', link: '#' },
               { name: 'Отследить посылку', link: '#' }
            ],
            profile: [
               { name: 'Личный Кабинет', link: '#' },
               { name: 'История заказов', link: '#' },
               { name: 'Избранное', link: '#' }
            ]
         };
      },

      methods: {
         openModalWindow() {
            this.openModal({ name: 'ModalCallBack', isActive: true });
         },

         transferUpButton(value) {
            this.$emit('up-button', value);
         }
      }
   };
</script>
