'use strict';

export default {
   namespaced: true,

   state: {
      product: {}
   },

   mutations: {
      ADD_PRODUCT(state, product) {
         state.product = product;
      }
   }
};
