import { request } from '../generic.service';

class ProductsService {
   async getProductsFromCategory(section, value) {
      const params = new URLSearchParams({ section, value }).toString();

      const products = await request('products', `category?${params}`, 'GET');
      return await products.json();
   }

   async getProducts({ page, limit, section, value }) {
      const params = new URLSearchParams({ page, limit, section, value }).toString();

      const response = await request('products', `?${params}`, 'GET');
      return await response.json();
   }
}

export default new ProductsService();
