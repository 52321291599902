<template>
   <article class="social-networks">
      <h3 class="social-networks__title">
         <slot name="title"></slot>
      </h3>

      <ul class="social-networks__list">
         <li v-for="network in socialNetworks" :key="network.name" class="social-networks__item">
            <a :href="network.address" class="social-networks__link">
               <component :is="network.component" class="social-networks__img" />
            </a>
         </li>
      </ul>
   </article>
</template>

<script>
   import FacebookIcon from '@/components/Icons/SocialNetworks/FacebookIcon';
   import InstagramIcon from '@/components/Icons/SocialNetworks/InstagramIcon';
   import VkontakteIcon from '@/components/Icons/SocialNetworks/VKontakteIcon';

   export default {
      name: 'SocialNetworks',

      components: {
         FacebookIcon,
         InstagramIcon,
         VkontakteIcon
      },

      data() {
         return {
            socialNetworks: [
               {
                  name: 'instagram',
                  address: 'https://www.instagram.com/',
                  component: 'InstagramIcon'
               },
               { name: 'vkontakte', address: 'https://vk.com/', component: 'VkontakteIcon' },
               { name: 'facebook', address: 'https://facebook.com/', component: 'FacebookIcon' }
            ]
         };
      }
   };
</script>
