import { createStore } from 'vuex';
import cart from './modules/modal/cart';
import favorites from './modules/modal/favorites';
import fastBuy from './modules/modal/fastBuy';
import reviews from './modules/reviews';
import product from './modules/products/product';
import products from './modules/products/products';
import filters from './modules/filters';
import post from './modules/blog/post';
import posts from './modules/blog/posts';

export default createStore({
   modules: { cart, favorites, fastBuy, post, posts, reviews, product, products, filters }
});
