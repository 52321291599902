<template>
   <app-header @app-header="setHeader" />
   <app-navigation />
   <router-view></router-view>
   <app-advantages />
   <app-footer @up-button="setUpButton" />

   <app-modal @modal-methods="setModalSettings" />
</template>

<script>
   import AppHeader from '@/components/templates/AppHeader';
   import AppNavigation from '@/components/templates/Navigation/AppNavigation';
   import AppAdvantages from '@/components/templates/AppAdvantages';
   import AppFooter from '@/components/templates/AppFooter';
   import AppModal from '@/components/modal/AppModal';
   import smoothScroll from 'smoothscroll-polyfill';
   import { computed } from 'vue';

   export default {
      components: {
         AppHeader,
         AppNavigation,
         AppAdvantages,
         AppFooter,
         AppModal
      },

      provide() {
         return {
            header: computed(() => this.header),
            upButton: computed(() => this.upButton),
            openModal: computed(() => this.modalSettings.openModal),
            closeModal: computed(() => this.modalSettings.closeModal),
            backToTopOfPage: this.backToTopOfPage
         };
      },

      data() {
         return {
            header: {},
            modalSettings: {},
            upButton: {}
         };
      },

      created() {
         this.$store.dispatch('cart/loadDataFromLocalStorage');
         this.$store.dispatch('favorites/loadDataFromLocalStorage');
      },

      methods: {
         backToTopOfPage(behavior = 'auto') {
            smoothScroll.polyfill();
            this.header.scrollIntoView({ behavior });
         },

         setHeader(value) {
            this.header = value;
         },

         setUpButton(value) {
            this.upButton = value;
         },

         setModalSettings(value) {
            this.modalSettings = value;
         }
      }
   };
</script>
